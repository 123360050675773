import * as React from 'react';
import {
   Accordion, AccordionDetails,
   AccordionSummary, Autocomplete, Backdrop,
   Button, Checkbox,
   Dialog,
   FormControl, FormControlLabel, FormGroup, FormLabel,
   InputLabel,
   MenuItem, Radio, RadioGroup,
   Select,
   Slide, Stack, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PhotoCamera} from "@mui/icons-material";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import General from "./inspection_blocks/general";
import Jobsite from "./inspection_blocks/jobsite";
import Elevations from "./inspection_blocks/elevations";
import Roof from "./inspection_blocks/roof";
import Siding from "./inspection_blocks/siding";
import Interior from "./inspection_blocks/interior";
import {
   getInspectionById,
   createNewInspectionAndPrepareData,
   updateInspection,
   moveReportToCompleteStage,
   updateDealPhotos,
   createNewContact,
   createNewDeal,
   getLinkedInspections, copyPhotosToLinkedDeal
} from "../context/inspections/ECActions";
import {useSnackbar} from "notistack";
import CustomCamera from "./tools/custom_camera";
import Photos from "./inspection_blocks/photos";
import CircularProgress from '@mui/material/CircularProgress';
import Reports from "./inspection_blocks/reports";
import Windows from "./inspection_blocks/windows";
import Gutters from "./inspection_blocks/gutters";
import RoofTestSquares from "./inspection_blocks/roof/roofTestSquares";


export default function ReportEdit() {

   const { flagCheckCompleteReady, canCompleteTheJob, isDealWasCreated, flagGuttersReadyToSave, flagWindowsReadyToSave, readyWINDOWSdata, readyGUTTERSdata, isAutomaticSaving, photoDataToUpload, readySKYLIGHTSdata, flagSkylightsReadyToSave, readyROOFPHOTOSdata, flagRoofPhotosReadyToSave, readyTESTSQUAREdata, flagTestSquareReadyToSave, readyVENTILATIONdata, flagVentilationReadyToSave, readyROOFFIELDdata, flagRooffieldReadyToSave, readyINTERIORdata, flagInteriorReadyToSave, readySIDINGdata, flagSidingReadyToSave, readyELEVATIONSdata, flagElevationsReadyToSave, readyGENERALdata, readyJOBSITEdata, flagCleanAllControlsData, showInspectionDialog, flagGeneralReadyToSave, flagJobsiteReadyToSave, editInspectionId, dispatch, access_token_data, inspectionAllData } = useContext(ECContext)
   const [open, setOpen] = React.useState(false);
   const { enqueueSnackbar } = useSnackbar();

   const [section, setSection] = React.useState(56);
   const [structure, setStructure] = React.useState(10);
   const [structureOptions, setStructureOptions] = React.useState([{id: 20, label: '––– Add New ––––'} ]);
   //const [structureOptions, setStructureOptions] = React.useState([{id: 20, label: '––– Add New ––––'}, {id: 10, label: '1. Main Structure'}]);


   const [recordType, setRecordType] = React.useState(189); // 189: Inspection (main structure), 191: Additional Structure
   const [linkedMainStructure, setLinkedMainStructure] = React.useState('');

   const [showProgress, setShowProgress] = React.useState(false);
   const [firstAutoSaving, setFirstAutoSaving] = React.useState(false);


//SAVING BLOCK *********************************************************************************************************

   useEffect(() => {


// console.log("We're in InspectionEdit. SAVING.")
// console.log(`flagGeneralReadyToSave: ${flagGeneralReadyToSave}, flagJobsiteReadyToSave: ${flagJobsiteReadyToSave}
// flagInteriorReadyToSave: ${flagInteriorReadyToSave}`)


      if(
         // flagGeneralReadyToSave == true
         // && flagJobsiteReadyToSave == true
         // && flagElevationsReadyToSave == true
         // && flagSidingReadyToSave == true
         // && flagWindowsReadyToSave == true
         flagGuttersReadyToSave == true
         // && flagInteriorReadyToSave == true
         // && flagRooffieldReadyToSave == true
         // && flagVentilationReadyToSave == true
         // && flagTestSquareReadyToSave == true
         //&& flagRoofPhotosReadyToSave== true
         //         && flagSkylightsReadyToSave == true
      ) {

         setShowProgress(true)


         // т.к. мы попали сюда, значит все страницы заполнили свои данные и их можно брать и в Report (когда выбрано там No-Photos)
         dispatch({ type: 'SET_NOPHOTOS_READY_DATA' })

         const wrap = async () => {






//             console.log('>>> Saving allDataForJsonField:')
// console.log(allDataForJsonField)


            // дозаполним InsuranceAllData полями отсюда, из InspectionEdit
            let inspectionEditBlockData = {
               ...inspectionAllData,

               ...readyGUTTERSdata,
            }


            const allDataForJsonField = {
               ...inspectionAllData,

               ...readyGUTTERSdata,
               photoFolderId: inspectionAllData.photoFolderId
            }
            //delete allDataForJsonField.PROPERTY_1107 // eдалим json (а то он каждый раз добавляляс :)

            console.log(' Data for JSON.stringifay while saving')
            console.log(allDataForJsonField)


            inspectionEditBlockData.ufCrm5_1680634157 = JSON.stringify(allDataForJsonField) //inspectionAllData

            console.log(' >>> Updating Report with data: >>>')
            console.log(inspectionEditBlockData)

//return;

            //const updateDealPhotosRes = updateDealPhotos(access_token_data, inspectionEditBlockData)

            const updateResult = updateInspection(access_token_data, inspectionEditBlockData)


            if(updateResult.hasOwnProperty('error')) {
               const errorMessage = updateResult.error_description
               enqueueSnackbar(`Can't save: ${errorMessage}`, 'error');
            }
            else {


               // if(!isAutomaticSaving)
               //    enqueueSnackbar('Saved!', 'error');
            }
            dispatch({ type: 'DROP_SAVE_REQUEST' })

            setShowProgress(false)
         }
         wrap().then(r => {});

         dispatch({ type: 'DROP_GUTTERS_READY_TO_SAVE_FLAG' })
      }


   }, [flagGuttersReadyToSave]);

   useEffect(() => {

      if(flagCheckCompleteReady == true) {

         //Lets determine what kind of Jod do we have. For Roofing and Siding we should check mandatory fields before saving

         if(!canCompleteTheJob.canCompleteTheJob) {
            const errorMessage = 'Sube todas las fotos obligatorias antes de completar el Trabajo: ' + canCompleteTheJob.message.toString()
            //const errorMessage = 'Upload all the mandatory photos before completing the Job: ' + canCompleteTheJob.message.toString()
            enqueueSnackbar(`No se puede completar: ${errorMessage}`, 'error');
            //enqueueSnackbar(`Can't complete: ${errorMessage}`, 'error');
         }
         else {

// тут переводим наш Report в статус 'Completed by Sub', т.к. все проверки на обязательные фото пройдены

            const moveResult = moveReportToCompleteStage(access_token_data, editInspectionId)

// запостим все фото в связанную сделку

            const copyPhotosResutl = copyPhotosToLinkedDeal(access_token_data, editInspectionId)







            enqueueSnackbar(`The Job has been successflly marked as completed!`, 'info');
            handleClose()
         }


         dispatch({ type: 'DROP_CHECK_COMPLETE_READY' })
      }

   }, [flagCheckCompleteReady]);



   useEffect(() => {
      setOpen(showInspectionDialog)

      if(showInspectionDialog) {

// если открываемый диалог – для НОВОЙ Inspection
         if(inspectionAllData.isNew === true) {

         }
         else {
// если открываемый диалог – для СУЩЕСТВУЮЩЕЙ Inspection
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })

// тут мы открываем существующий Inspection. Поэтому загрузим по нему данные из Б24 и скажем всем компонентам их подгрузить
            const wrap = async () => {
               const inspectionData = await getInspectionById(access_token_data, editInspectionId)

console.log('Inspection data loaded for editing:')
console.log(inspectionData)

               const loadedInspectionAllData = JSON.parse(inspectionData.ufCrm5_1680634157)
               //const loadedInspectionAllData = JSON.parse(Object.values(inspectionData.ufCrm5_1680634157))
               loadedInspectionAllData.isNew = false
               loadedInspectionAllData.inspectionId = editInspectionId

               loadedInspectionAllData.incomingPhotos = inspectionData.ufCrm5_1680685107

console.log('loadedInspectionAllData:')
console.log(loadedInspectionAllData)


               //dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...loadedInspectionAllData}})
               dispatch({ type: 'RELOAD_DATA', payload: {...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData
               //dispatch({ type: 'RELOAD_DATA', payload: {...inspectionData, ...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData

            }
            wrap()


         }
         setSection(56)
         dispatch({ type: 'SET_INSPECTION_GUTTERS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
      }

   }, [showInspectionDialog]);



   const handleClose = () => {
      hideAllSections();

      setSection(56)
      setStructure(10)
      //setStructureOptions([{id: 20, label: '––– Add New ––––'}, {id: 10, label: '1. Main Structure'}])
      setRecordType(189) // 189: Inspection (main structure), 191: Additional Structure
      setLinkedMainStructure('')


      //handleSave()

      dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {}})
      dispatch({ type: 'CLEAN_CONTROLS_DATA'})
      dispatch({ type: 'SET_HIDE_INSPECTION_DIALOG' })
      dispatch({ type: 'REFRESH_INSPECTIONS_LIST' })
   };

   const handleSave = () => {

      setShowProgress(true)
      setFirstAutoSaving(false)
      dispatch({ type: 'SAVE_REQUEST' })

      //hideAllSections();
      //dispatch({ type: 'SET_HIDE_INSPECTION_DIALOG' })
   };

   const handleCompleteTheJob = async () => {

      dispatch({ type: 'CHECK_CAN_COMPLETE_REQUEST' })

      // const tempInspectionData = await getInspectionById(access_token_data, editInspectionId)
      // console.log('FINISHING. tempInspectionData: ', tempInspectionData)
      // const title = tempInspectionData.title
      // if(title.indexOf('Roof') !== -1) {
      //    alert('it is Roofing!')
      // }
      // else if(title.indexOf('Siding') !== -1) {
      //    alert('Siding')
      // }


      // setShowProgress(true)
      // setFirstAutoSaving(false)
      // dispatch({ type: 'SAVE_REQUEST' })
      //hideAllSections();
      //dispatch({ type: 'SET_HIDE_INSPECTION_DIALOG' })
   };



   const [expanded, setExpanded] = React.useState('panel_inspection');
   const [expanded2, setExpanded2] = React.useState('');
   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   const handleChange2 = (panel) => (event, newExpanded) => {
      setExpanded2(newExpanded ? panel : false);
   };


   const hideAllSections = () => {

      dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_JOBSITE_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ELEVATIONS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ROOF_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: '', elevationType: "", showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_SIDING_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_WINDOWS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_GUTTERS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_INTERIOR_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_PHOTOS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_REPORTS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_DAMAGE_DATA', payload: {inspectionId: editInspectionId, elevationType: "testSquares", showBlock: false} })
   }


   const handleSectionChange = (event) => {

      const selectedSection = event.target.value
      setSection(selectedSection);

      hideAllSections()

      // turning on the selected section
      // eslint-disable-next-line default-case
      switch (selectedSection) {
         case 10:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 20:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_JOBSITE_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 30:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_ELEVATIONS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 40:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_ROOF_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 50:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_SIDING_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 55:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_WINDOWS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 56:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_GUTTERS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 60:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_INTERIOR_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 65:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_DAMAGE_DATA', payload: {inspectionId: editInspectionId, elevationType: "testSquares", showBlock: true} })
            break
         case 70:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_PHOTOS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 80:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_REPORTS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break

      }


   };

   const handleStructureChange = (event) => {

      const selectedSection = event.target.value
      setStructure(selectedSection);

console.log(`selectedSection: ${selectedSection}`)

      if(selectedSection == '20') { // Adding New Structure ============================================================

         let newStructName = prompt("Please enter new structure type", "Garage");

         if (newStructName == null)
            return;

         let label = ''
         //
         // dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {}})
         // dispatch({ type: 'CLEAN_CONTROLS_DATA'})
         //

         setSection(10)

         const createInspectionsAndFolder = async (newStructName) => {

            // new Item for Combobox
            let newID = structureOptions.length + 10
            const newNumber = structureOptions.length
            label = `${newNumber}. ${newStructName}`

            const newAdditionStructureInspectionName = label + ' [' + Math.floor(new Date().getTime() / 1000) + ']'

            setRecordType(191) //Additional Structure



            const params = {
               NAME: newAdditionStructureInspectionName,
               PROPERTY_797: linkedMainStructure,              //_Linked Main structure
               //PROPERTY_797: linkedMainStructure,              //_Linked Main structure
               PROPERTY_795: 191,                               // 'Record Type' -> 'Additional Structure'

                  //...emptyStructure
            }

            const inspectionAndFolderIDs = await createNewInspectionAndPrepareData(access_token_data, params)

console.log('inspectioAllData after we added additionalStructure inspection:')
console.log({...inspectionAllData, inspectionId: inspectionAndFolderIDs.newInspectionId, photoFolderId: inspectionAndFolderIDs.newInspectionFolderId, isAdditonalStructureInspection: true})




            dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {
               //...inspectionAllData,
               inspectionId: inspectionAndFolderIDs.newInspectionId,
               photoFolderId: inspectionAndFolderIDs.newInspectionFolderId,
               isAdditonalStructureInspection: true,
               PROPERTY_797: inspectionAllData.inspectionId,
               NAME: newAdditionStructureInspectionName,
               PROPERTY_795: 191,                               // 'Record Type' -> 'Additional Structure'

               //PROPERTY_1107: [] //inspectionAllData (for Json)
                  //...emptyStructure
            }
            })
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {showBlock: true} })
            dispatch({ type: 'CLEAN_CONTROLS_DATA'})



            setStructureOptions([...structureOptions, {id: inspectionAndFolderIDs.newInspectionId, label: label, structureInspectionId: inspectionAndFolderIDs.newInspectionId }])
            setStructure(inspectionAndFolderIDs.newInspectionId)

// !!! сразу запишем созданную доп. постройку
            dispatch({ type: 'SAVE_REQUEST' })


         }
         createInspectionsAndFolder(newStructName).then(r => {});



      }

      if(selectedSection != '10' && selectedSection != '20') { // Loading existing  ============================================================


         const cur = structureOptions.filter( str => str.id == selectedSection )
         const main = structureOptions.filter( str => str.label == '1. Main Structure' )

//alert(`(cur[0].label: ${cur[0].label}`)

         if (cur[0].label != '1. Main Structure') {
            setRecordType(191) //Additional Structure
            setLinkedMainStructure(main[0].id)
         }
         else {
            setRecordType(189) //main structure
            setLinkedMainStructure('')
         }



         dispatch({ type: 'CLEAN_CONTROLS_DATA'})
         dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: selectedSection, showBlock: true} })

         // тут мы открываем существующий Inspection. Поэтому загрузим по нему данные из Б24 и скажем всем компонентам их подгрузить

         const wrap = async (selectedSection) => {
            const inspectionData = await getInspectionById(access_token_data, selectedSection)

            console.log('Inspection data loaded for editing:')
            console.log(inspectionData)

            const loadedInspectionAllData = JSON.parse(Object.values(inspectionData.PROPERTY_1107)[0])
            inspectionData.isNew = false
            inspectionData.inspectionId = selectedSection

            dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...loadedInspectionAllData}})
            dispatch({ type: 'RELOAD_DATA', payload: {...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData
            //dispatch({ type: 'RELOAD_DATA', payload: {...inspectionData, ...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData

            setSection(10)

         }
         wrap(selectedSection)

      }


   };



   return (

         <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            {/*<AppBar sx={{ position: 'sticky', position: '-webkit-sticky', top: 0  }}>*/}
            <AppBar sx={{ position: 'sticky', top: 0  }}>
               <Toolbar>
                  <IconButton
                     edge="start"
                     color="inherit"
                     onClick={handleClose}
                     aria-label="close"
                  >
                     <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                     ...
                  </Typography>
                  <Button color="inherit" onClick={handleCompleteTheJob} sx={{ position: 'sticky' }} >
                     Complete the Job
                  </Button>
               </Toolbar>
            </AppBar>

            {/*<Box sx={{mx: 'auto', mt: 2, width: '100%', display: 'flex',}}>
               <FormControl fullWidth>
                  <InputLabel id="structure-label">Structure</InputLabel>
                  <Select
                     labelId="structure-select-label"
                     id="structure-select"
                     value={structure}
                     label="Structure"
                     onChange={handleStructureChange}
                  >

                     {structureOptions.map((option, index) => (
                        <MenuItem value={option.id}>{option.label}</MenuItem>
                     ))}

                  </Select>
               </FormControl>
               <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={section}
                     label="Section"
                     onChange={handleSectionChange}
                  >
                     <MenuItem value={10}>General</MenuItem>
                     <MenuItem value={65}>Initial Inspection Damages</MenuItem>
                     <MenuItem value={20}>Jobsite</MenuItem>
                     <MenuItem value={30}>Elevations</MenuItem>
                     <MenuItem value={40}>Roof</MenuItem>
                     <MenuItem value={50}>Siding</MenuItem>
                     <MenuItem value={55}>Windows</MenuItem>
                     <MenuItem value={56}>Gutters</MenuItem>
                     <MenuItem value={60}>Interior</MenuItem>
                     <MenuItem value={70}>Photos</MenuItem>
                     <MenuItem value={80}>Reports</MenuItem>
                  </Select>
               </FormControl>
            </Box>*/}

            {/*<General/>*/}
            {/*<Jobsite/>*/}
            {/*<Elevations/>*/}
            {/*<Roof/>*/}
            {/*<Siding/>*/}
            {/*<Windows/>*/}
            <Gutters/>
            {/*<Interior/>*/}
            {/*<Photos />*/}
            {/*<Reports />*/}
            {/*<RoofTestSquares />*/}

            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={showProgress}
               //onClick={handleClose}
            >
               <CircularProgress color="inherit" />
            </Backdrop>


            <Stack direction="row" alignItems="center" spacing={2}>
               <CustomCamera />
            </Stack>


         </Dialog>



);
}