import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReportEdit from "./ReportEdit";
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import { getB24accessToken, getDealsTest, getInspectionsList} from '../context/inspections/ECActions'
import Spinner from './layout/Spinner'



function createData(ID, NAME) {
   return { ID, NAME };
}

const rows = [
   createData(1, 'Test1'),
   createData(2, "Test2"),
];



export default function ReportsList() {

   const { dispatch, inspections, access_token_data, loading } = useContext(ECContext)


   if (!loading) {

      const openInspection = (event) => {

         const inspectionId = event.target.parentElement.id
         dispatch({type: 'SET_SHOW_INSPECTION_DIALOG', payload: inspectionId})
      }

      return (

         <>

            <TableContainer component={Paper}>
               <Table aria-label="Inspections">
                  <TableBody>
                     {inspections.map((inspection) => (
                        <TableRow
                           id={inspection.id}
                           key={inspection.id}
                           sx={{'&:last-child td, &:last-child th': {border: 0}}}
                           onClick={openInspection}
                        >
                           {/*<TableCell width="50px" align="right">{inspection.ID}</TableCell>*/}
                           <TableCell component="th" scope="row">{inspection.title}</TableCell>
                           {/*<TableCell component="th" scope="row">{inspection.id} : {inspection.title}</TableCell>*/}
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>


            <ReportEdit/>


         </>
      );
   }
   else {
      return <Spinner />
   }
}