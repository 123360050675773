import * as React from 'react';

import SearchAppBar from "../components/SearchAppBar";
import ReportsList from "../components/ReportsList";
import AddNewReportFAB from "../components/AddNewReportFAB";


function Home() {


   return (
      <>
         <SearchAppBar/>
         <ReportsList/>

{/*
         <AddNewReportFAB/>
*/}
      </>
  )
}

export default Home


