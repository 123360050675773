import React, {useContext, useEffect, useState} from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import ImagePreview from './ImagePreview';
import ECContext from "../../context/inspections/ECContext";
import Box from "@mui/material/Box"; // source code : ./src/demo/AppWithImagePreview/ImagePreview

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {Backdrop, Stack} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	createNewInspectionAndPrepareData, getImageUrl, getInspectionById,
	getLinkedInspections,
	saveGeneralBlock
} from "../../context/inspections/ECActions";
import {savePhotoIntoFolder} from "../../context/inspections/ECActions";
import spinner from "../layout/assets/loading.gif";
import ImageEditor from "./imageEditor";
import {Image} from "mui-image";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CircularProgress from '@mui/material/CircularProgress';


export default function CustomCamera() {

	const { showCamera, showCameraFor, dispatch, access_token_data, inspectionAllData } = useContext(ECContext)
	const [show, setShow] = React.useState(false);
	const [photoOfFrontOfProperty, setPhotoOfFrontOfProperty] = React.useState([]);


	const [disableSaveButton, setDisableSaveButton] = React.useState(false);

	const [selectedImageIndex, setSelectedImageIndex] = React.useState('');
	const [sourceForImageEditor, setSourceForImageEditor] = React.useState('');
	const [images, setImages] = React.useState([]);

	const [showProgress, setShowProgress] = React.useState(false);

	const reduce = require('image-blob-reduce')();

	useEffect(() => {
		if(showCamera) {
			console.log(`We're inside Custom Camera`)
		}
		setShowProgress(false)
		setShow(showCamera)
		setDisableSaveButton(true)
	}, [showCamera]);

	const [dataUri, setDataUri] = useState('');
	//const [savedPhotoData, setSavedPhotoData] = useState([]);

	let savedPhotoData = []

	const [openImageEditor, setOpenImageEditor] = useState(false);



	// const [uploadFlag, setUploadFlag] = useState(false);
	// const [uploadData, setUploadData] = useState(false);
	//
	//
	//
	// useEffect(() => {
	//
	// }, [uploadFlag]);

// 	useEffect(() => {
//
// console.log('savedPhotoData', savedPhotoData)
// console.log('photoOfFrontOfProperty', photoOfFrontOfProperty)
//
//
// 	}, [savedPhotoData]);







	const toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});


	function handleTakePhoto (dataUri) {

		// Do stuff with the photo...
		console.log('takePhoto');

		setPhotoOfFrontOfProperty([...photoOfFrontOfProperty, dataUri]);

		setDisableSaveButton(false)


//		setPhotoLoadinInProgress(true);
//		setDisableSaveButton(true)
	}


	function handleTakePhotoAnimationDone (dataUri) {}

	function handleCameraError (error) {
		console.log('handleCameraError', error);
	}

	function handleCameraStart (stream) {
		console.log('handleCameraStart');
	}

	function handleCameraStop () {
		console.log('handleCameraStop');
	}




	const handleInsertPhotos = async () => {

		if(photoOfFrontOfProperty.length == 0) { // если ничего не наснимали, то просто выходим
			setImages([])
			dispatch({ type: 'SET_SHOW_CAMERA_OFF' })
			setShowProgress(false)
		}



		setShowProgress(true)
		//setDisableSaveButton(true);

// console.log("showCameraFor:")
// console.log(showCameraFor)

		// запишем эти данные в наш глобальный inspectionAllData
		let tmp = {...inspectionAllData}

// но сначалан надо обновить токены на существующие файлы поля, в которое мы добавляем
		let images = []
		const refreshTokens = async () => {

				if(showCameraFor.property == 'photoOfFrontOfProperty')
					return;

				if(tmp.hasOwnProperty(showCameraFor.property) == false)
					return;

				if(tmp[showCameraFor.property].length > 0) { // если есть, чьи токены надо обновлять
					//let images = []
					for (let i = 0; i < tmp[showCameraFor.property].length; i++) {
						const imageUrl = await getImageUrl(access_token_data, tmp[showCameraFor.property][i].ID)
						images.push({'ID': imageUrl.ID, 'FILE_ID': imageUrl.FILE_ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
					}

					tmp[showCameraFor.property] = images
					dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...tmp}})

					console.log(`->> Setting up inspectionAllData with tmp. showCameraFor.property: ${showCameraFor.property}`)
					console.log(tmp)


				}
		}
		await refreshTokens()

		setImages(images)

		tmp = {...inspectionAllData}


//console.log(`<<-- Continue after refreshTokens() `)




// Преобразуем и сохраним все фото

		photoOfFrontOfProperty.forEach( (curImage) => {

			let dataUri = curImage;// setPhotoOfFrontOfProperty[setPhotoOfFrontOfProperty.length - 1]
			const dataURItoBlob = (dataURI) => {
				// convert base64 to raw binary data held in a string
				var byteString = atob(dataURI.split(',')[1]);

				// separate out the mime component
				var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

				// write the bytes of the string to an ArrayBuffer
				var ab = new ArrayBuffer(byteString.length);

				// create a view into the buffer
				var ia = new Uint8Array(ab);

				// set the bytes of the buffer to the correct values
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				// write the ArrayBuffer to a blob, and you're done
				var blob = new Blob([ab], {type: mimeString});
				return blob;

			}
			const realBlob = dataURItoBlob(dataUri)

			//let _locaSavedPhotos = []

			reduce.toBlob(realBlob, { max: 800 })
				.then(async blob => {

					const resizedImg = await toBase64(blob)

					const base64 = resizedImg;
					const params = {
						id: inspectionAllData.photoFolderId,
						data: {
							NAME: "inspectionPhoto.jpg"
						},
						fileContent: resizedImg.replace('data:image/jpeg;base64,', ''),
						generateUniqueName: true
					}


					savePhotoIntoFolder(access_token_data, params)
						.then(async _savedPhotoData => {

console.log('---- adding to savedPhotoData.')
console.log('savedPhotoData', savedPhotoData)
console.log('_savedPhotoData', _savedPhotoData)

							//setSavedPhotoData([...savedPhotoData, _savedPhotoData])

							savedPhotoData.push(_savedPhotoData)






							if(savedPhotoData.length == photoOfFrontOfProperty.length && savedPhotoData.length !== 0) {
// Отправим фото в inspectionAllData

								console.log('Hey! we are inside :)')

								let tmp = {...inspectionAllData}


								if(showCameraFor.page == "gutters") {

									if(tmp[showCameraFor.property] === undefined)
										tmp[showCameraFor.property] = []

									tmp[showCameraFor.property] = [...images, ...savedPhotoData]
									dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_PHOTO', payload: tmp } )
									dispatch({type: 'SET_UPDATE_PHOTO_FOR_GUTTERS'})
								}


								//setSavedPhotoData([])
								setPhotoOfFrontOfProperty([])
								setImages([])
								dispatch({ type: 'SET_SHOW_CAMERA_OFF' })
								setShowProgress(false)


							}







							// setDisableSaveButton(false)
							// setPhotoLoadingStatus({status: false, data: ''})
						})

				});
		}) //forEach







//		setShow(false);
	};

	const handleClose = () => {
		setPhotoOfFrontOfProperty([])
		//setSavedPhotoData([])
		setImages([])
		dispatch({ type: 'SET_SHOW_CAMERA_OFF' })
		setShow(false);
	};


	return (
		<div>
			<Dialog
				fullScreen
				open={show}
				onClose={handleClose}
			>




				{/*<Box sx={{mx: 'auto', m: 1, p: 1, width: '100%', display: 'flex', }}>*/}
				<Box sx={{mx: 'auto', m: 1, p: 1, width: '100%', display: 'flex' }}>
					<Camera
						onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
						//onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
						//onCameraError = { (error) => { handleCameraError(error); } }
						idealFacingMode = {FACING_MODES.ENVIRONMENT}
						idealResolution = {{width: 800, height: 600}}
						imageType = {IMAGE_TYPES.JPG}
						imageCompression = {0.9}
						isMaxResolution = {false}
						//isMaxResolution = {true}
						isImageMirror = {false}
						isSilentMode = {false}
						//isDisplayStartCameraError = {true}
						isFullscreen = {false}
						sizeFactor = {1}
						/*
												onCameraStart = { (stream) => { handleCameraStart(stream); } }
												onCameraStop = { () => { handleCameraStop(); } }
						*/
					/>
				</Box>



				<>
					{/*Buttons "Back" and prev. "Insert Photos"*/}
					<Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
						<Button variant="outlined" onClick={handleInsertPhotos} sx={{width: "220px", height: "45px"}}>Save</Button>
						{/*<Button variant="outlined" disabled={disableSaveButton} onClick={handleInsertPhotos} sx={{width: "220px", height: "45px"}}>Back</Button>*/}
						{/*<Button variant="outlined" sx={{width: "150px"}} onClick={handleClose}>Cancel</Button>*/}
					</Stack>

					<Box sx={{mx: 'auto', m: 1, p: 1, width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
						{photoOfFrontOfProperty.map((image, index) => (
							<Stack direction="row" alignItems="center" spacing={1} >
								<img
									/*id={'inspectionImage'+index}*/
									alt="Not found"
									width="70px"
									height="70px"
									src={image}
								/>


								{/*photoLoadinInProgress*/}

								{
									(true/*!photoLoadinInProgress*/)
										?
										<Stack direction="column" alignItems="left" spacing={1}>
											<Button sx={{width: "40px", maxHeight: "40px", pt: 1, m:0 }}
													  onClick={() => {
														  setSourceForImageEditor(photoOfFrontOfProperty[index])
														  setSelectedImageIndex(index)
														  setOpenImageEditor(true)
													  }}>
												<ModeEditIcon  />
											</Button>
											<Button sx={{width: "40px", maxHeight: "40px", pt: 1, m:0 }}
													  onClick={() => {
														  let temp = [...photoOfFrontOfProperty]
														  temp.splice(index, 1);
														  setPhotoOfFrontOfProperty(temp)

														  if(temp.length == 0)
															  setDisableSaveButton(true)

													  }}>
												<RemoveCircleOutlineIcon  />
											</Button>
										</Stack>
										:
										<Box sx={{ display: 'flex' }}>
											<CircularProgress />
										</Box>
								}



							</Stack>
						))}
					</Box>

				</>





{/*
				<IconButton
					edge="start"
					color="inherit"
					onClick={handleClose}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
*/}

				<div>

					<ImageEditor
						source={sourceForImageEditor}
						handleSave={ (editedImageObject) => {
							console.log('Yeah', editedImageObject)

							setDisableSaveButton(true)

// console.log(`selectedImageIndex: ${selectedImageIndex}`)
// console.log('photoOfFrontOfProperty', photoOfFrontOfProperty)

							let tmp = photoOfFrontOfProperty;
							tmp.splice(selectedImageIndex, 1, editedImageObject.imageBase64)
							setPhotoOfFrontOfProperty(tmp)

							// а теперь её надо закинуть этот файл в Б24 и получть по нему данные

							const base64 = editedImageObject.imageBase64;
							const params = {
								id: inspectionAllData.photoFolderId,
								data: {
									NAME: "inspectionPhoto.jpg"
								},
								fileContent: base64.replace('data:image/jpeg;base64,', ''),
								generateUniqueName: true
							}
							savePhotoIntoFolder(access_token_data, params)
								.then(async _savedPhotoData => {


console.log(`selectedImageIndex: ${selectedImageIndex}`)
console.log('savedPhotosData before:', savedPhotoData)

									//temp.splice(index, 1);
									let tmp1 = savedPhotoData
									tmp1.splice(selectedImageIndex, 1);

console.log('we want to update it with that:', [...tmp1, _savedPhotoData])

									//setSavedPhotoData([...tmp1, _savedPhotoData])

									setDisableSaveButton(false)
								})


						}}
						imageEditorOpen={openImageEditor}
						handleClose={() => setOpenImageEditor(false) }/>
				</div>

				<ImageEditor />

				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={showProgress}
					//onClick={handleClose}
				>
					<CircularProgress color="inherit" />
				</Backdrop>


			</Dialog>



		</div>
	);
}