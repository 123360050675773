import {
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel, FormGroup,
   FormLabel, ImageList, ImageListItem, ListItemButton, ListItemIcon, Radio, RadioGroup, Stack,
   TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import { Image } from 'mui-image'
import spinner from "../layout/assets/loading.gif";
import {getImageUrl, getInspectionById, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import reduce from "image-blob-reduce";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";


export default function Gutters() {

   const { checkIfCanCompleteTheJob, showCameraFor, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, flagNeedToUpdatePhotosForGuttersBlock, inspectionGuttersBlockInfo, inspectionAllData, dispatch, showCamera, saveRequest, editInspectionId, inspections, access_token_data } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [incomingPhotos, setIncomingPhotos] = React.useState([]);
   const [testPhotos, setTestPhotos] = React.useState([]);
   const [isRoofing, setIsRoofing] = React.useState(false);

   const [tearOffShinglePhotosBefore, setTearOffShinglePhotosBefore] = React.useState([]);
   const [tearOffShinglePhotosAfter, setTearOffShinglePhotosAfter] = React.useState([]);
   const [multipleLayerPhotosBefore, setMultipleLayerPhotosBefore] = React.useState([]);
   const [multipleLayerPhotosAfter, setMultipleLayerPhotosAfter] = React.useState([]);
   const [feltCutOutIfMultipleLayersBefore, setFeltCutOutIfMultipleLayersBefore] = React.useState([]);
   const [feltCutOutIfMultipleLayersAfter, setFeltCutOutIfMultipleLayersAfter] = React.useState([]);
   const [starterEavesRakesBefore, setStarterEavesRakesBefore] = React.useState([]);
   const [starterEavesRakesAfter, setStarterEavesRakesAfter] = React.useState([]);
   const [iceWaterPhotosBefore, setIceWaterPhotosBefore] = React.useState([]);
   const [iceWaterPhotosAfter, setIceWaterPhotosAfter] = React.useState([]);
   const [deckingWithTapeMeasurementsBefore, setDeckingWithTapeMeasurementsBefore] = React.useState([]);
   const [deckingWithTapeMeasurementsAfter, setDeckingWithTapeMeasurementsAfter] = React.useState([]);
   const [deckingRepairBefore, setDeckingRepairBefore] = React.useState([]);
   const [deckingRepairAfter, setDeckingRepairAfter] = React.useState([]);
   const [ridgeVentBefore, setRidgeVentBefore] = React.useState([]);
   const [ridgeVentAfter, setRidgeVentAfter] = React.useState([]);
   const [dumpsterLocationBefore, setDumpsterLocationBefore] = React.useState([]);
   const [dumpsterLocationAfter, setDumpsterLocationAfter] = React.useState([]);
   const [completedRoofBefore, setCompletedRoofBefore] = React.useState([]);
   const [completedRoofAfter, setCompletedRoofAfter] = React.useState([]);
   const [bushesObstructionsBefore, setBushesObstructionsBefore] = React.useState([]);
   const [bushesObstructionsAfter, setBushesObstructionsAfter] = React.useState([]);
   const [sidingBefore, setSidingBefore] = React.useState([]);
   const [sidingAfter, setSidingAfter] = React.useState([]);
   const [accessoriesLightsOutletsACCablesHosesBefore, setAccessoriesLightsOutletsACCablesHosesBefore] = React.useState([]);
   const [accessoriesLightsOutletsACCablesHosesAfter, setAccessoriesLightsOutletsACCablesHosesAfter] = React.useState([]);
   const [flashingTapeBefore, setFlashingTapeBefore] = React.useState([]);
   const [flashingTapeAfter, setFlashingTapeAfter] = React.useState([]);
   const [houseWrapBefore, setHouseWrapBefore] = React.useState([]);
   const [houseWrapAfter, setHouseWrapAfter] = React.useState([]);
   const [fanfoldBefore, setFanfoldBefore] = React.useState([]);
   const [fanfoldAfter, setFanfoldAfter] = React.useState([]);
   const [dripCapBefore, setDripCapBefore] = React.useState([]);
   const [dripCapAfter, setDripCapAfter] = React.useState([]);
   const [flashingLStepDormerBefore, setFlashingLStepDormerBefore] = React.useState([]);
   const [flashingLStepDormerAfter, setFlashingLStepDormerAfter] = React.useState([]);
   const [caulkingBefore, setCaulkingBefore] = React.useState([]);
   const [caulkingAfter, setCaulkingAfter] = React.useState([]);


   useEffect(() => {


      const checkRoofing = async () => {
         const tempInspectionData = await getInspectionById(access_token_data, editInspectionId)
         const title = tempInspectionData.title
         if(title.indexOf('Roof') !== -1) {
            setIsRoofing(true)
            //testCanCompleteTheJobs(true)
         }

      }
      checkRoofing()



      setShow(inspectionGuttersBlockInfo.showBlock)
   }, [inspectionGuttersBlockInfo]);

   useEffect(() => {

      if(checkIfCanCompleteTheJob == true) {

         testCanCompleteTheJobs()

         dispatch({ type: 'DROP_CHECK_CAN_COMPLETE_REQUEST' })
      }


   }, [checkIfCanCompleteTheJob]);

   useEffect(() => {

      setIncomingPhotos([]);
      setTestPhotos([]);

      setTearOffShinglePhotosBefore([]);
      setTearOffShinglePhotosAfter([]);
      setMultipleLayerPhotosBefore([]);
      setMultipleLayerPhotosAfter([]);
      setFeltCutOutIfMultipleLayersBefore([]);
      setFeltCutOutIfMultipleLayersAfter([]);
      setStarterEavesRakesBefore([]);
      setStarterEavesRakesAfter([]);
      setIceWaterPhotosBefore([]);
      setIceWaterPhotosAfter([]);
      setDeckingWithTapeMeasurementsBefore([]);
      setDeckingWithTapeMeasurementsAfter([]);
      setDeckingRepairBefore([]);
      setDeckingRepairAfter([]);
      setRidgeVentBefore([]);
      setRidgeVentAfter([]);
      setDumpsterLocationBefore([]);
      setDumpsterLocationAfter([]);
      setCompletedRoofBefore([]);
      setCompletedRoofAfter([]);
      setBushesObstructionsBefore([]);
      setBushesObstructionsAfter([]);
      setSidingBefore([]);
      setSidingAfter([]);
      setAccessoriesLightsOutletsACCablesHosesBefore([]);
      setAccessoriesLightsOutletsACCablesHosesAfter([]);
      setFlashingTapeBefore([]);
      setFlashingTapeAfter([]);
      setHouseWrapBefore([]);
      setHouseWrapAfter([]);
      setFanfoldBefore([]);
      setFanfoldAfter([]);
      setDripCapBefore([]);
      setDripCapAfter([]);
      setFlashingLStepDormerBefore([]);
      setFlashingLStepDormerAfter([]);
      setCaulkingBefore([]);
      setCaulkingAfter([]);


   }, [flagCleanAllControlsData]);

   useEffect(() => {


      if(flagNeedToUpdatePhotosForGuttersBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_GUTTERS' })

         if(inspectionAllData.hasOwnProperty('testPhotos') && showCameraFor.property == 'testPhotos') {
            setTestPhotos([
               ...inspectionAllData.testPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('tearOffShinglePhotosBefore') && showCameraFor.property == 'tearOffShinglePhotosBefore') {
            setTearOffShinglePhotosBefore([
               ...inspectionAllData.tearOffShinglePhotosBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('tearOffShinglePhotosAfter') && showCameraFor.property == 'tearOffShinglePhotosAfter') {
            setTearOffShinglePhotosAfter([
               ...inspectionAllData.tearOffShinglePhotosAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('multipleLayerPhotosBefore') && showCameraFor.property == 'multipleLayerPhotosBefore') {
            setMultipleLayerPhotosBefore([
               ...inspectionAllData.multipleLayerPhotosBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('multipleLayerPhotosAfter') && showCameraFor.property == 'multipleLayerPhotosAfter') {
            setMultipleLayerPhotosAfter([
               ...inspectionAllData.multipleLayerPhotosAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('feltCutOutIfMultipleLayersBefore') && showCameraFor.property == 'feltCutOutIfMultipleLayersBefore') {
            setFeltCutOutIfMultipleLayersBefore([
               ...inspectionAllData.feltCutOutIfMultipleLayersBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('feltCutOutIfMultipleLayersAfter') && showCameraFor.property == 'feltCutOutIfMultipleLayersAfter') {
            setFeltCutOutIfMultipleLayersAfter([
               ...inspectionAllData.feltCutOutIfMultipleLayersAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('starterEavesRakesBefore') && showCameraFor.property == 'starterEavesRakesBefore') {
            setStarterEavesRakesBefore([
               ...inspectionAllData.starterEavesRakesBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('starterEavesRakesAfter') && showCameraFor.property == 'starterEavesRakesAfter') {
            setStarterEavesRakesAfter([
               ...inspectionAllData.starterEavesRakesAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('iceWaterPhotosBefore') && showCameraFor.property == 'iceWaterPhotosBefore') {
            setIceWaterPhotosBefore([
               ...inspectionAllData.iceWaterPhotosBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('iceWaterPhotosAfter') && showCameraFor.property == 'iceWaterPhotosAfter') {
            setIceWaterPhotosAfter([
               ...inspectionAllData.iceWaterPhotosAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('deckingWithTapeMeasurementsBefore') && showCameraFor.property == 'deckingWithTapeMeasurementsBefore') {
            setDeckingWithTapeMeasurementsBefore([
               ...inspectionAllData.deckingWithTapeMeasurementsBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('deckingWithTapeMeasurementsAfter') && showCameraFor.property == 'deckingWithTapeMeasurementsAfter') {
            setDeckingWithTapeMeasurementsAfter([
               ...inspectionAllData.deckingWithTapeMeasurementsAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('deckingRepairBefore') && showCameraFor.property == 'deckingRepairBefore') {
            setDeckingRepairBefore([
               ...inspectionAllData.deckingRepairBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('deckingRepairAfter') && showCameraFor.property == 'deckingRepairAfter') {
            setDeckingRepairAfter([
               ...inspectionAllData.deckingRepairAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('ridgeVentBefore') && showCameraFor.property == 'ridgeVentBefore') {
            setRidgeVentBefore([
               ...inspectionAllData.ridgeVentBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('ridgeVentAfter') && showCameraFor.property == 'ridgeVentAfter') {
            setRidgeVentAfter([
               ...inspectionAllData.ridgeVentAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('dumpsterLocationBefore') && showCameraFor.property == 'dumpsterLocationBefore') {
            setDumpsterLocationBefore([
               ...inspectionAllData.dumpsterLocationBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('dumpsterLocationAfter') && showCameraFor.property == 'dumpsterLocationAfter') {
            setDumpsterLocationAfter([
               ...inspectionAllData.dumpsterLocationAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('completedRoofBefore') && showCameraFor.property == 'completedRoofBefore') {
            setCompletedRoofBefore([
               ...inspectionAllData.completedRoofBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('completedRoofAfter') && showCameraFor.property == 'completedRoofAfter') {
            setCompletedRoofAfter([
               ...inspectionAllData.completedRoofAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('bushesObstructionsBefore') && showCameraFor.property == 'bushesObstructionsBefore') {
            setBushesObstructionsBefore([
               ...inspectionAllData.bushesObstructionsBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('bushesObstructionsAfter') && showCameraFor.property == 'bushesObstructionsAfter') {
            setBushesObstructionsAfter([
               ...inspectionAllData.bushesObstructionsAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('sidingBefore') && showCameraFor.property == 'sidingBefore') {
            setSidingBefore([
               ...inspectionAllData.sidingBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('sidingAfter') && showCameraFor.property == 'sidingAfter') {
            setSidingAfter([
               ...inspectionAllData.sidingAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('accessoriesLightsOutletsACCablesHosesBefore') && showCameraFor.property == 'accessoriesLightsOutletsACCablesHosesBefore') {
            setAccessoriesLightsOutletsACCablesHosesBefore([
               ...inspectionAllData.accessoriesLightsOutletsACCablesHosesBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('accessoriesLightsOutletsACCablesHosesAfter') && showCameraFor.property == 'accessoriesLightsOutletsACCablesHosesAfter') {
            setAccessoriesLightsOutletsACCablesHosesAfter([
               ...inspectionAllData.accessoriesLightsOutletsACCablesHosesAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('flashingTapeBefore') && showCameraFor.property == 'flashingTapeBefore') {
            setFlashingTapeBefore([
               ...inspectionAllData.flashingTapeBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('flashingTapeAfter') && showCameraFor.property == 'flashingTapeAfter') {
            setFlashingTapeAfter([
               ...inspectionAllData.flashingTapeAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('houseWrapBefore') && showCameraFor.property == 'houseWrapBefore') {
            setHouseWrapBefore([
               ...inspectionAllData.houseWrapBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('houseWrapAfter') && showCameraFor.property == 'houseWrapAfter') {
            setHouseWrapAfter([
               ...inspectionAllData.houseWrapAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('fanfoldBefore') && showCameraFor.property == 'fanfoldBefore') {
            setFanfoldBefore([
               ...inspectionAllData.fanfoldBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('fanfoldAfter') && showCameraFor.property == 'fanfoldAfter') {
            setFanfoldAfter([
               ...inspectionAllData.fanfoldAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('dripCapBefore') && showCameraFor.property == 'dripCapBefore') {
            setDripCapBefore([
               ...inspectionAllData.dripCapBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('dripCapAfter') && showCameraFor.property == 'dripCapAfter') {
            setDripCapAfter([
               ...inspectionAllData.dripCapAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('flashingLStepDormerBefore') && showCameraFor.property == 'flashingLStepDormerBefore') {
            setFlashingLStepDormerBefore([
               ...inspectionAllData.flashingLStepDormerBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('flashingLStepDormerAfter') && showCameraFor.property == 'flashingLStepDormerAfter') {
            setFlashingLStepDormerAfter([
               ...inspectionAllData.flashingLStepDormerAfter]
            )
         }
         if(inspectionAllData.hasOwnProperty('caulkingBefore') && showCameraFor.property == 'caulkingBefore') {
            setCaulkingBefore([
               ...inspectionAllData.caulkingBefore]
            )
         }
         if(inspectionAllData.hasOwnProperty('caulkingAfter') && showCameraFor.property == 'caulkingAfter') {
            setCaulkingAfter([
               ...inspectionAllData.caulkingAfter]
            )
         }

         dispatch({ type: 'SAVE_REQUEST' })


      }

   }, [flagNeedToUpdatePhotosForGuttersBlock]);

   useEffect(() => {


                  const transformSavingData = (prop) => {

                     return prop.map((fileData, index) => {
                        // if(fileData.IS_NEW == true) {
                        //    prop[index].IS_NEW = false
                        //    return { ID: fileData.FILE_ID}
                        // }
                        // else
                           return { ID: fileData.ID}
                     }).filter(x => x !== undefined)
                  }


      if(saveRequest === true) {

         let guttersBlockData = {

            //ufCrm5_1680685107: transformSavingData(incomingPhotos), //Incoming Photos
            ufCrm5_1680695087: transformSavingData(testPhotos),      //Test Photos

            ufCrm5_1680853900: transformSavingData(tearOffShinglePhotosBefore),
            ufCrm5_1680854093: transformSavingData(tearOffShinglePhotosAfter),
            ufCrm5_1680854202: transformSavingData(multipleLayerPhotosBefore),
            ufCrm5_1680854247: transformSavingData(multipleLayerPhotosAfter),
            ufCrm5_1680854513: transformSavingData(feltCutOutIfMultipleLayersBefore),
            ufCrm5_1680854531: transformSavingData(feltCutOutIfMultipleLayersAfter),
            ufCrm5_1680854710: transformSavingData(starterEavesRakesBefore),
            ufCrm5_1680854724: transformSavingData(starterEavesRakesAfter),
            ufCrm5_1680854758: transformSavingData(iceWaterPhotosBefore),
            ufCrm5_1680854772: transformSavingData(iceWaterPhotosAfter),
            ufCrm5_1680854793: transformSavingData(deckingWithTapeMeasurementsBefore),
            ufCrm5_1680854806: transformSavingData(deckingWithTapeMeasurementsAfter),
            ufCrm5_1680854823: transformSavingData(deckingRepairBefore),
            ufCrm5_1680854833: transformSavingData(deckingRepairAfter),
            ufCrm5_1680854846: transformSavingData(ridgeVentBefore),
            ufCrm5_1680854857: transformSavingData(ridgeVentAfter),
            ufCrm5_1680854870: transformSavingData(dumpsterLocationBefore),
            ufCrm5_1680854881: transformSavingData(dumpsterLocationAfter),
            ufCrm5_1680854895: transformSavingData(completedRoofBefore),
            ufCrm5_1680854905: transformSavingData(completedRoofAfter),
            ufCrm5_1680854937: transformSavingData(bushesObstructionsBefore),
            ufCrm5_1680854946: transformSavingData(bushesObstructionsAfter),
            ufCrm5_1680854958: transformSavingData(sidingBefore),
            ufCrm5_1680854967: transformSavingData(sidingAfter),
            ufCrm5_1680854985: transformSavingData(accessoriesLightsOutletsACCablesHosesBefore),
            ufCrm5_1680854995: transformSavingData(accessoriesLightsOutletsACCablesHosesAfter),
            ufCrm5_1680855006: transformSavingData(flashingTapeBefore),
            ufCrm5_1680855014: transformSavingData(flashingTapeAfter),
            ufCrm5_1680855025: transformSavingData(houseWrapBefore),
            ufCrm5_1680855037: transformSavingData(houseWrapAfter),
            ufCrm5_1680855052: transformSavingData(fanfoldBefore),
            ufCrm5_1680855062: transformSavingData(fanfoldAfter),
            ufCrm5_1680855075: transformSavingData(dripCapBefore),
            ufCrm5_1680855092: transformSavingData(dripCapAfter),
            ufCrm5_1680855104: transformSavingData(flashingLStepDormerBefore),
            ufCrm5_1680855114: transformSavingData(flashingLStepDormerAfter),
            ufCrm5_1680856109: transformSavingData(caulkingBefore),
            ufCrm5_1680855127: transformSavingData(caulkingAfter),

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_GUTTERS_BLOCK', payload: guttersBlockData } )

console.log('Updationg Report with:')
console.log(guttersBlockData)


         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_GUTTERS_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      //if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;
      //console.log('RELOADING data... in windows.jsx')
      // console.log('current inspectionAllData:')
      // console.log(inspectionAllData)


         const incomingPhotos = async () => {
            if(inspectionAllData.hasOwnProperty('incomingPhotos' )) {
               if(inspectionAllData.incomingPhotos.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.incomingPhotos.length; i++) {
                     // const imageUrl = await getImageUrl(access_token_data, inspectionAllData.incomingPhotos[i].id)
                     // images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DETAIL_URL/*, 'FILE_ID': inspectionAllData.incomingPhotos[i].id*/ })

                     images.push( {'ID': inspectionAllData.incomingPhotos[i].id, 'DETAIL_URL': inspectionAllData.incomingPhotos[i].urlMachine })
                  }
                  setIncomingPhotos(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                     'incomingPhotos': images
                  }})
               }
            }
         }
      incomingPhotos()

      const testPhotos = async () => {
            if(inspectionAllData.hasOwnProperty('testPhotos' )) {
               if(inspectionAllData.testPhotos.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.testPhotos.length; i++) {
                     const imageUrl = await getImageUrl(access_token_data, inspectionAllData.testPhotos[i].ID)
                     images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL/*, 'FILE_ID': inspectionAllData.incomingPhotos[i].id*/ })

//                     images.push( {'ID': inspectionAllData.testPhotos[i].ID, 'DETAIL_URL': inspectionAllData.testPhotos[i].DETAIL_URL })
                  }
                  setTestPhotos(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                     'testPhotos': images
                  }})
               }
            }
         }
      testPhotos()

      const tearOffShinglePhotosBefore = async () => {
         if(inspectionAllData.hasOwnProperty('tearOffShinglePhotosBefore' )) {
            if(inspectionAllData.tearOffShinglePhotosBefore.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.tearOffShinglePhotosBefore.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.tearOffShinglePhotosBefore[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL })
               }
               setTearOffShinglePhotosBefore(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'tearOffShinglePhotosBefore': images
                  }})
            }
         }
      }
      tearOffShinglePhotosBefore()

      const tearOffShinglePhotosAfter = async () => {
         if(inspectionAllData.hasOwnProperty('tearOffShinglePhotosAfter' )) {
            if(inspectionAllData.tearOffShinglePhotosAfter.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.tearOffShinglePhotosAfter.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.tearOffShinglePhotosAfter[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL/*, 'FILE_ID': inspectionAllData.incomingPhotos[i].id*/ })

//                     images.push( {'ID': inspectionAllData.testPhotos[i].ID, 'DETAIL_URL': inspectionAllData.testPhotos[i].DETAIL_URL })
               }
               setTearOffShinglePhotosAfter(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'tearOffShinglePhotosAfter': images
                  }})
            }
         }
      }
      tearOffShinglePhotosAfter()

      const multipleLayerPhotosBefore = async () => {
         if(inspectionAllData.hasOwnProperty('multipleLayerPhotosBefore' )) {
            if(inspectionAllData.multipleLayerPhotosBefore.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.multipleLayerPhotosBefore.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.multipleLayerPhotosBefore[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL/*, 'FILE_ID': inspectionAllData.incomingPhotos[i].id*/ })

//                     images.push( {'ID': inspectionAllData.testPhotos[i].ID, 'DETAIL_URL': inspectionAllData.testPhotos[i].DETAIL_URL })
               }
               setMultipleLayerPhotosBefore(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'multipleLayerPhotosBefore': images
                  }})
            }
         }
      }
      multipleLayerPhotosBefore()

      const multipleLayerPhotosAfter = async () => {
         if(inspectionAllData.hasOwnProperty('multipleLayerPhotosAfter' )) {
            if(inspectionAllData.multipleLayerPhotosAfter.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.multipleLayerPhotosAfter.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.multipleLayerPhotosAfter[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL/*, 'FILE_ID': inspectionAllData.incomingPhotos[i].id*/ })

//                     images.push( {'ID': inspectionAllData.testPhotos[i].ID, 'DETAIL_URL': inspectionAllData.testPhotos[i].DETAIL_URL })
               }
               setMultipleLayerPhotosAfter(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'multipleLayerPhotosAfter': images
                  }})
            }
         }
      }
      multipleLayerPhotosAfter()


// -----automatic block begens here
      const updatePhoto = async (fieldName) => {

//console.log('fieldName: ', fieldName)

         if(inspectionAllData.hasOwnProperty(fieldName )) {
            if(inspectionAllData[fieldName].length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData[fieldName].length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData[fieldName][i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL })
               }

               switch (fieldName) {
                  case 'feltCutOutIfMultipleLayersBefore':
                     setFeltCutOutIfMultipleLayersBefore(images)
                     break;
                  case 'feltCutOutIfMultipleLayersAfter':
                     setFeltCutOutIfMultipleLayersAfter(images)
                     break;
                  case 'starterEavesRakesBefore':
                     setStarterEavesRakesBefore(images)
                     break;
                  case 'starterEavesRakesAfter':
                     setStarterEavesRakesAfter(images)
                     break;
                  case 'iceWaterPhotosBefore':
                     setIceWaterPhotosBefore(images)
                     break;
                  case 'iceWaterPhotosAfter':
                     setIceWaterPhotosAfter(images)
                     break;
                  case 'deckingWithTapeMeasurementsBefore':
                     setDeckingWithTapeMeasurementsBefore(images)
                     break;
                  case 'deckingWithTapeMeasurementsAfter':
                     setDeckingWithTapeMeasurementsAfter(images)
                     break;
                  case 'deckingRepairBefore':
                     setDeckingRepairBefore(images)
                     break;
                  case 'deckingRepairAfter':
                     setDeckingRepairAfter(images)
                     break;
                  case 'ridgeVentBefore':
                     setRidgeVentBefore(images)
                     break;
                  case 'ridgeVentAfter':
                     setRidgeVentAfter(images)
                     break;
                  case 'dumpsterLocationBefore':
                     setDumpsterLocationBefore(images)
                     break;
                  case 'dumpsterLocationAfter':
                     setDumpsterLocationAfter(images)
                     break;
                  case 'completedRoofBefore':
                     setCompletedRoofBefore(images)
                     break;
                  case 'completedRoofAfter':
                     setCompletedRoofAfter(images)
                     break;
                  case 'bushesObstructionsBefore':
                     setBushesObstructionsBefore(images)
                     break;
                  case 'bushesObstructionsAfter':
                     setBushesObstructionsAfter(images)
                     break;
                  case 'sidingBefore':
                     setSidingBefore(images)
                     break;
                  case 'sidingAfter':
                     setSidingAfter(images)
                     break;
                  case 'accessoriesLightsOutletsACCablesHosesBefore':
                     setAccessoriesLightsOutletsACCablesHosesBefore(images)
                     break;
                  case 'accessoriesLightsOutletsACCablesHosesAfter':
                     setAccessoriesLightsOutletsACCablesHosesAfter(images)
                     break;
                  case 'flashingTapeBefore':
                     setFlashingTapeBefore(images)
                     break;
                  case 'flashingTapeAfter':
                     setFlashingTapeAfter(images)
                     break;
                  case 'houseWrapBefore':
                     setHouseWrapBefore(images)
                     break;
                  case 'houseWrapAfter':
                     setHouseWrapAfter(images)
                     break;
                  case 'fanfoldBefore':
                     setFanfoldBefore(images)
                     break;
                  case 'fanfoldAfter':
                     setFanfoldAfter(images)
                     break;
                  case 'dripCapBefore':
                     setDripCapBefore(images)
                     break;
                  case 'dripCapAfter':
                     setDripCapAfter(images)
                     break;
                  case 'flashingLStepDormerBefore':
                     setFlashingLStepDormerBefore(images)
                     break;
                  case 'flashingLStepDormerAfter':
                     setFlashingLStepDormerAfter(images)
                     break;
                  case 'caulkingBefore':
                     setCaulkingBefore(images)
                     break;
                  case 'caulkingAfter':

//console.log('We are insind caulingAfter block')
                     setCaulkingAfter(images)
                     break;
               }

               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     ['fieldName']: images
                  }})
            }
         }
      }

      updatePhoto('feltCutOutIfMultipleLayersBefore')
      updatePhoto('feltCutOutIfMultipleLayersAfter')
      updatePhoto('starterEavesRakesBefore')
      updatePhoto('starterEavesRakesAfter')
      updatePhoto('iceWaterPhotosBefore')
      updatePhoto('iceWaterPhotosAfter')
      updatePhoto('deckingWithTapeMeasurementsBefore')
      updatePhoto('deckingWithTapeMeasurementsAfter')
      updatePhoto('deckingRepairBefore')
      updatePhoto('deckingRepairAfter')
      updatePhoto('ridgeVentBefore')
      updatePhoto('ridgeVentAfter')
      updatePhoto('dumpsterLocationBefore')
      updatePhoto('dumpsterLocationAfter')
      updatePhoto('completedRoofBefore')
      updatePhoto('completedRoofAfter')
      updatePhoto('bushesObstructionsBefore')
      updatePhoto('bushesObstructionsAfter')
      updatePhoto('sidingBefore')
      updatePhoto('sidingAfter')
      updatePhoto('accessoriesLightsOutletsACCablesHosesBefore')
      updatePhoto('accessoriesLightsOutletsACCablesHosesAfter')
      updatePhoto('flashingTapeBefore')
      updatePhoto('flashingTapeAfter')
      updatePhoto('houseWrapBefore')
      updatePhoto('houseWrapAfter')
      updatePhoto('fanfoldBefore')
      updatePhoto('fanfoldAfter')
      updatePhoto('dripCapBefore')
      updatePhoto('dripCapAfter')
      updatePhoto('flashingLStepDormerBefore')
      updatePhoto('flashingLStepDormerAfter')
      updatePhoto('caulkingBefore')
      updatePhoto('caulkingAfter')




   }, [flagReloadDatafromInspectionAllData]);


   const testCanCompleteTheJobs = () => {

      let canCompleteTheJob = false

      // проверим заполненность обязательных полей для Roof и Siding и установаим признак возможности сделать Report Completed.

console.log('isRoofing: ',isRoofing)

      let mandatoryFieldsInfo = []
      if(isRoofing == true) {

         if(tearOffShinglePhotosBefore.length == 0 || tearOffShinglePhotosAfter.length == 0)
            mandatoryFieldsInfo.push(' Fotos Removiendo Tejas')

         if(feltCutOutIfMultipleLayersBefore.length == 0 || feltCutOutIfMultipleLayersAfter.length == 0)
            mandatoryFieldsInfo.push(' Capa Base (Recorte Si Tiene Multiples Capas)')

         if(starterEavesRakesBefore.length == 0 || starterEavesRakesAfter.length == 0)
            mandatoryFieldsInfo.push(' Teja De Hilada Inicial (Rastrillos y Aleros)')

         if(iceWaterPhotosBefore.length == 0 || iceWaterPhotosAfter.length == 0)
            mandatoryFieldsInfo.push(' Fotos de Protección Papel de Ice and Water')

         if(dumpsterLocationBefore.length == 0 || dumpsterLocationAfter.length == 0)
            mandatoryFieldsInfo.push(' Ubicación de Basurero')

         if(completedRoofBefore.length == 0 || completedRoofAfter.length == 0)
            mandatoryFieldsInfo.push(' Techo Completado')
      }
      else { // here's the Siding

         if(bushesObstructionsBefore.length == 0 || bushesObstructionsAfter.length == 0)
            mandatoryFieldsInfo.push(' Arbustos/ Obstrucciones')
         if(sidingBefore.length == 0 || sidingAfter.length == 0)
            mandatoryFieldsInfo.push(' Revestimiento')
         if(accessoriesLightsOutletsACCablesHosesBefore.length == 0 || accessoriesLightsOutletsACCablesHosesAfter.length == 0)
            mandatoryFieldsInfo.push(' Accesorios (Luces, Enchufes, A/C, Cables, Mangueras)')
         if(flashingTapeBefore.length == 0 || flashingTapeAfter.length == 0)
            mandatoryFieldsInfo.push('Cinta Intermitente')
         if(houseWrapBefore.length == 0 || houseWrapAfter.length == 0)
            mandatoryFieldsInfo.push('Envoltura de la casa')
         if(dripCapBefore.length == 0 || dripCapAfter.length == 0)
            mandatoryFieldsInfo.push('Tapa de Goteo')
         if(flashingLStepDormerBefore.length == 0 || flashingLStepDormerAfter.length == 0)
            mandatoryFieldsInfo.push('Vierteaguas (L, Escalonado, Buhardilla)')
         if(caulkingBefore.length == 0 || caulkingAfter.length == 0)
            mandatoryFieldsInfo.push('Silicon')

      }

      if(mandatoryFieldsInfo.length == 0)
         canCompleteTheJob = true

      console.log('canCompleteTheJob: ',canCompleteTheJob)
      console.log('mandatoryFieldsInfo: ',mandatoryFieldsInfo)

      dispatch({ type: 'CAN_COMPLETE_THE_JOB', payload: {canCompleteTheJob: canCompleteTheJob, message: mandatoryFieldsInfo} } )
      dispatch({ type: 'CHECK_COMPLETE_READY' } )

   }


   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.ID != event.target.value )

                        // if(drawerVarName == "incomingPhotos") {
                        //    setIncomingPhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }

                        if(drawerVarName == "testPhotos") {
                           setTestPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   return (


      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >

{/*incomingPhotos*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <React.Fragment key={'right'} ><Drawer anchor={'right'} open={state[drawerVarName]} onClose={toggleDrawer(drawerVarName, false, drawerVar)} sx={{zIndex: 9999}}>{list(drawerVar)}</Drawer></React.Fragment>
{/*            <Typography sx={{my: 'auto'}}>Report Info</Typography>*/}
         </Stack>

         <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <Typography variant="h4" sx={{my: 'auto'}}>Project Photos</Typography>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {incomingPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={50} style={{ borderRadius: 6 }} onClick={toggleDrawer('incomingPhotos', true, incomingPhotos)}/>
            ))}
         </Stack>


{/*testPhotos*/}
{/*         <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{my: 'auto'}}>Test Photos</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'testPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, testPhotos: [...testPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setTestPhotos([...testPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {testPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('testPhotos', true, testPhotos)}/>
            ))}
         </Stack>*/}


<Box sx={{display: isRoofing === true ? '' : 'none'}}>

   {/*Techo/Roof*/}
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} pt={2}>
               <Typography variant="h4" sx={{my: 'auto', 'text-decoration': 'underline'}}>Techo/Roof</Typography>
            </Stack>

   {/*Before/After*/}
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
               <Typography variant="body1" sx={{my: 'auto', 'text-decoration': 'underline'}}>ANTERIOR/BEFORE</Typography>
               <Typography variant="body1" sx={{my: 'auto', 'text-decoration': 'underline'}}>DESPUES/AFTER</Typography>
            </Stack>

   {/*Tear-off Shingle Photos*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'tearOffShinglePhotosBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, tearOffShinglePhotosBefore: [...tearOffShinglePhotosBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setTearOffShinglePhotosBefore([...tearOffShinglePhotosBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {tearOffShinglePhotosBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('tearOffShinglePhotosBefore', true, tearOffShinglePhotosBefore)}/>
                     ))}
                  </Stack>
               </Stack>



               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Fotos Removiendo Tejas</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Tear-off Shingle Photos)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'tearOffShinglePhotosAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, tearOffShinglePhotosAfter: [...tearOffShinglePhotosAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setTearOffShinglePhotosAfter([...tearOffShinglePhotosAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {tearOffShinglePhotosAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('tearOffShinglePhotosAfter', true, tearOffShinglePhotosAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Multiple Layer Photos) (If Applicable)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'multipleLayerPhotosBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, multipleLayerPhotosBefore: [...multipleLayerPhotosBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setMultipleLayerPhotosBefore([...multipleLayerPhotosBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {multipleLayerPhotosBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('multipleLayerPhotosBefore', true, multipleLayerPhotosBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Fotos de Varias Capas de Tejas  (Cuando Applicable)</Typography>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Multiple Layer Photos) (If Applicable)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'multipleLayerPhotosAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, multipleLayerPhotosAfter: [...multipleLayerPhotosAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setMultipleLayerPhotosAfter([...multipleLayerPhotosAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {multipleLayerPhotosAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('multipleLayerPhotosAfter', true, multipleLayerPhotosAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Felt) (Cut-out if Multiple Layers)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'feltCutOutIfMultipleLayersBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, feltCutOutIfMultipleLayersBefore: [...feltCutOutIfMultipleLayersBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFeltCutOutIfMultipleLayersBefore([...feltCutOutIfMultipleLayersBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {feltCutOutIfMultipleLayersBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('feltCutOutIfMultipleLayersBefore', true, feltCutOutIfMultipleLayersBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Capa Base (Recorte Si Tiene Multiples Capas)</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Felt) (Cut-out if Multiple Layers)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'feltCutOutIfMultipleLayersAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, feltCutOutIfMultipleLayersAfter: [...feltCutOutIfMultipleLayersAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFeltCutOutIfMultipleLayersAfter([...feltCutOutIfMultipleLayersAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {feltCutOutIfMultipleLayersAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('feltCutOutIfMultipleLayersAfter', true, feltCutOutIfMultipleLayersAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Starter) (Eaves & Rakes)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'starterEavesRakesBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, starterEavesRakesBefore: [...starterEavesRakesBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setStarterEavesRakesBefore([...starterEavesRakesBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {starterEavesRakesBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('starterEavesRakesBefore', true, starterEavesRakesBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Teja De Hilada Inicial (Rastrillos y Aleros)</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Starter) (Eaves & Rakes)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'starterEavesRakesAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, starterEavesRakesAfter: [...starterEavesRakesAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setStarterEavesRakesAfter([...starterEavesRakesAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {starterEavesRakesAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('starterEavesRakesAfter', true, starterEavesRakesAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Ice & Water Photos)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'iceWaterPhotosBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, iceWaterPhotosBefore: [...iceWaterPhotosBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setIceWaterPhotosBefore([...iceWaterPhotosBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {iceWaterPhotosBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('iceWaterPhotosBefore', true, iceWaterPhotosBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Fotos de Protección Papel de Ice and Water</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Ice & Water Photos)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'iceWaterPhotosAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, iceWaterPhotosAfter: [...iceWaterPhotosAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setIceWaterPhotosAfter([...iceWaterPhotosAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {iceWaterPhotosAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('iceWaterPhotosAfter', true, iceWaterPhotosAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Decking) (With Tape Measurements) */}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'deckingWithTapeMeasurementsBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, deckingWithTapeMeasurementsBefore: [...deckingWithTapeMeasurementsBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDeckingWithTapeMeasurementsBefore([...deckingWithTapeMeasurementsBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {deckingWithTapeMeasurementsBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('deckingWithTapeMeasurementsBefore', true, deckingWithTapeMeasurementsBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Cubierto de Techo de Madera (Con Cinta Métrica)</Typography>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Decking) (With Tape Measurements)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'deckingWithTapeMeasurementsAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, deckingWithTapeMeasurementsAfter: [...deckingWithTapeMeasurementsAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDeckingWithTapeMeasurementsAfter([...deckingWithTapeMeasurementsAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {deckingWithTapeMeasurementsAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('deckingWithTapeMeasurementsAfter', true, deckingWithTapeMeasurementsAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Decking Repair)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'deckingRepairBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, deckingRepairBefore: [...deckingRepairBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDeckingRepairBefore([...deckingWithTapeMeasurementsBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {deckingRepairBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('deckingRepairBefore', true, deckingRepairBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Reparaciones de Madera (Plywood)</Typography>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Decking Repair)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'deckingRepairAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, deckingRepairAfter: [...deckingRepairAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDeckingRepairAfter([...deckingRepairAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {deckingRepairAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('deckingRepairAfter', true, deckingRepairAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Ridge Vent)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'ridgeVentBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, ridgeVentBefore: [...ridgeVentBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRidgeVentBefore([...ridgeVentBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {ridgeVentBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('ridgeVentBefore', true, ridgeVentBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Ventilación de Borde</Typography>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Ridge Vent)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'ridgeVentAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, ridgeVentAfter: [...ridgeVentAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRidgeVentAfter([...ridgeVentAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {ridgeVentAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('ridgeVentAfter', true, ridgeVentAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Dumpster Location)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'dumpsterLocationBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, dumpsterLocationBefore: [...dumpsterLocationBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDumpsterLocationBefore([...dumpsterLocationBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {dumpsterLocationBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('dumpsterLocationBefore', true, dumpsterLocationBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Ubicación de Basurero</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Dumpster Location)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'dumpsterLocationAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, dumpsterLocationAfter: [...dumpsterLocationAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDumpsterLocationAfter([...dumpsterLocationAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {dumpsterLocationAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('dumpsterLocationAfter', true, dumpsterLocationAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Completed Roof)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'completedRoofBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, completedRoofBefore: [...completedRoofBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCompletedRoofBefore([...completedRoofBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {completedRoofBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('completedRoofBefore', true, completedRoofBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Techo Completado</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Completed Roof)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'completedRoofAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, completedRoofAfter: [...completedRoofAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCompletedRoofAfter([...completedRoofAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {completedRoofAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('completedRoofAfter', true, completedRoofAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

</Box>




<Box sx={{display: isRoofing === true ? 'none' : ''}}>

   {/*Revestimiento/Siding*/}
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} pt={2}>
               <Typography variant="h4" sx={{my: 'auto', 'text-decoration': 'underline'}}>Revestimiento/Siding</Typography>
            </Stack>

   {/*Before/After*/}
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
               <Typography variant="body1" sx={{my: 'auto', 'text-decoration': 'underline'}}>ANTERIOR/BEFORE</Typography>
               <Typography variant="body1" sx={{my: 'auto', 'text-decoration': 'underline'}}>DESPUES/AFTER</Typography>
            </Stack>

   {/*(Bushes/Obstructions)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bushesObstructionsBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bushesObstructionsBefore: [...bushesObstructionsBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setBushesObstructionsBefore([...bushesObstructionsBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {bushesObstructionsBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('bushesObstructionsBefore', true, bushesObstructionsBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Arbustos/ Obstrucciones</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Bushes/Obstructions)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bushesObstructionsAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bushesObstructionsAfter: [...bushesObstructionsAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setBushesObstructionsAfter([...bushesObstructionsAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {bushesObstructionsAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('bushesObstructionsAfter', true, bushesObstructionsAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Siding)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'sidingBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, sidingBefore: [...sidingBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setSidingBefore([...sidingBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {sidingBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('sidingBefore', true, sidingBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Revestimiento</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Siding)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'sidingAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, sidingAfter: [...sidingAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setSidingAfter([...sidingAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {sidingAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('sidingAfter', true, sidingAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*Accessories (Lights, Outlets, A/C, Cables, Hoses)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'accessoriesLightsOutletsACCablesHosesBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, accessoriesLightsOutletsACCablesHosesBefore: [...accessoriesLightsOutletsACCablesHosesBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setAccessoriesLightsOutletsACCablesHosesBefore([...accessoriesLightsOutletsACCablesHosesBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {accessoriesLightsOutletsACCablesHosesBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('accessoriesLightsOutletsACCablesHosesBefore', true, accessoriesLightsOutletsACCablesHosesBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Accesorios (Luces, Enchufes, A/C, Cables, Mangueras)</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>Accessories (Lights, Outlets, A/C, Cables, Hoses)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'accessoriesLightsOutletsACCablesHosesAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, accessoriesLightsOutletsACCablesHosesAfter: [...accessoriesLightsOutletsACCablesHosesAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setAccessoriesLightsOutletsACCablesHosesAfter([...accessoriesLightsOutletsACCablesHosesAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {accessoriesLightsOutletsACCablesHosesAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('accessoriesLightsOutletsACCablesHosesAfter', true, accessoriesLightsOutletsACCablesHosesAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*Flashing Tape*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'flashingTapeBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, flashingTapeBefore: [...flashingTapeBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFlashingTapeBefore([...flashingTapeBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {flashingTapeBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('flashingTapeBefore', true, flashingTapeBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Cinta Intermitente</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>Flashing Tape</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'flashingTapeAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, flashingTapeAfter: [...flashingTapeAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFlashingTapeAfter([...flashingTapeAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {flashingTapeAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('flashingTapeAfter', true, flashingTapeAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(House Wrap)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'houseWrapBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, houseWrapBefore: [...houseWrapBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setHouseWrapBefore([...houseWrapBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {houseWrapBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('houseWrapBefore', true, houseWrapBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Envoltura de la casa</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(House Wrap)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'houseWrapAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, houseWrapAfter: [...houseWrapAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setHouseWrapAfter([...houseWrapAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {houseWrapAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('houseWrapAfter', true, houseWrapAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Fanfold)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'fanfoldBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, fanfoldBefore: [...fanfoldBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFanfoldBefore([...fanfoldBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {fanfoldBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('fanfoldBefore', true, fanfoldBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Insulation de Espuma</Typography>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Fanfold)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'fanfoldAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, fanfoldAfter: [...fanfoldAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFanfoldAfter([...fanfoldAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {fanfoldAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('fanfoldAfter', true, fanfoldAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Drip Cap)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'dripCapBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, dripCapBefore: [...dripCapBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDripCapBefore([...dripCapBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {dripCapBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('dripCapBefore', true, dripCapBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Tapa de Goteo</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Drip Cap)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'dripCapAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, dripCapAfter: [...dripCapAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDripCapAfter([...dripCapAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {dripCapAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('dripCapAfter', true, dripCapAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*Flashing (L, Step, Dormer)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'flashingLStepDormerBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, flashingLStepDormerBefore: [...flashingLStepDormerBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFlashingLStepDormerBefore([...flashingLStepDormerBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {flashingLStepDormerBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('flashingLStepDormerBefore', true, flashingLStepDormerBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                  <Stack direction="row">
                     <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                     <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Vierteaguas (L, Escalonado, Buhardilla)</Typography>
                  </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>Flashing (L, Step, Dormer)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'flashingLStepDormerAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, flashingLStepDormerAfter: [...flashingLStepDormerAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFlashingLStepDormerAfter([...flashingLStepDormerAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {flashingLStepDormerAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('flashingLStepDormerAfter', true, flashingLStepDormerAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

   {/*(Caulking)*/}
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} pt={2}>

               <Stack direction="column" >
                  <Stack direction="row" >
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'caulkingBefore', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, caulkingBefore: [...caulkingBefore, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCaulkingBefore([...caulkingBefore, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {caulkingBefore.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('caulkingBefore', true, caulkingBefore)}/>
                     ))}
                  </Stack>
               </Stack>

               <Stack direction="column" justifyContent="center">
                     <Stack direction="row">
                        <Box sx={{'font-size': '30px', 'color': 'red'}}>*</Box>
                        <Typography variant="body1" sx={{my: 'auto', 'text-align': 'center'}}>Silicon</Typography>
                     </Stack>
                  <Typography sx={{my: 'auto', 'text-align': 'center', 'font-size': '11px'}}>(Caulking)</Typography>
               </Stack>

               <Stack direction="column" >
                  <Stack direction="row">
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'caulkingAfter', page: 'gutters'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, caulkingAfter: [...caulkingAfter, ...photos, savedPhotoData]} })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCaulkingAfter([...caulkingAfter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }
                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
                  <Stack direction="row" spacing={0.5} sx={{ 'flex-wrap': 'wrap' }}>
                     {caulkingAfter.map((image, index) => (
                        <Image src={image.DETAIL_URL} showLoading width={20} style={{ borderRadius: 6 }} onClick={toggleDrawer('caulkingAfter', true, caulkingAfter)}/>
                     ))}
                  </Stack>
               </Stack>

            </Stack>

</Box>

      </Stack>
   );
}